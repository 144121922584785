import React, { Component } from "react"
import Hero from "../components/Hero"
import Layout from "../components/Layout"

export default class work extends Component {
  render() {
    return (
      <Layout>
        <Hero
          title="Our work"
          text="Explore our best cases and see how we created valuable products for various industries."
        />
      </Layout>
    )
  }
}
